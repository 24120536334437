<template>
  <footer class="footer">
    <nav class="page-wrapper">
      <ul class="footer__nav">
        <li class="footer__nav-item">
          <a href="https://t.me/avoVPN_video" target="_blank" rel="noopener noreferrer" v-html="$t('footer.instructions')"></a>
        </li>
        <li class="footer__nav-item">
          <router-link to="/rules" v-html="$t('footer.rules')" />
        </li>
        <li class="footer__nav-item">
          <router-link to="/policy" v-html="$t('footer.policy')" />
        </li>
        <li class="footer__nav-item">
          <router-link to="/contacts" v-html="$t('footer.contacts')" />
        </li>
      </ul>
    </nav>
  </footer>
</template>

<script>
export default {
  name: 'PageFooter'
}
</script>
